import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import VueEasyLightbox from "vue-easy-lightbox";

import swal from "sweetalert2";
window.Swal = swal;

const options = {
  hideProgressBar: true,
  pauseOnHover: false,
};
createApp(App)
  .use(Toast, options)
  .use(VueEasyLightbox)
  .use(store)
  .use(router)
  .mount("#app");
