<template>
  <div
    class="navbar-no-shadow wf-section"
    :class="{
      'white-navbar':
        scrollPosition > 50 || isOpen || this.$route.name != 'home',
    }"
  >
    <div
      data-animation="default"
      data-collapse="medium"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      class="navbar-no-shadow-container w-nav"
    >
      <div class="container-regular">
        <div class="navbar-wrapper">
          <a href="/" class="navbar-brand w-nav-brand">
            <img
              v-if="scrollPosition > 50 || isOpen || this.$route.name != 'home'"
              src="images/logo-white.png"
              loading="lazy"
              width="76"
              sizes="(max-width: 479px) 100vw, 100px"
              srcset="images/logo-white.png 500w, images/logo-white.png 578w"
              alt=""
              class="logoimage"
            />
            <img
              v-else
              src="images/logo-2.png"
              loading="lazy"
              width="76"
              sizes="(max-width: 479px) 100vw, 100px"
              srcset="images/logo-2.png 500w, images/logo-2.png 578w"
              alt=""
              class="logoimage"
            />
          </a>
          <!-- srcset="images/logo-p-500.jpg 500w, images/logo.jpg 578w" -->
          <nav role="navigation" class="nav-menu-wrapper w-nav-menu">
            <ul
              role="list"
              class="nav-menu w-list-unstyled"
              :style="{
                'max-height': !screenSize && `calc(${screenHeight}px - 81px`,
                'overflow-y': !screenSize && 'scroll',
              }"
            >
              <li>
                <a
                  href="/"
                  aria-current="page"
                  class="nav-link"
                  :class="{
                    'w--current':
                      !newsVisible &&
                      !articleVisible &&
                      this.$route.name == 'home',
                  }"
                  >Home</a
                >
              </li>
              <li class="dropdown">
                <div
                  :data-hover="screenSize"
                  data-delay="0"
                  class="nav-dropdown w-dropdown"
                >
                  <div class="nav-dropdown-toggle w-dropdown-toggle">
                    <a
                      href="/articles"
                      :class="{
                        'w--current':
                          this.$route.name == 'articles' ||
                          this.$route.name == 'single-article' ||
                          articleVisible,
                      }"
                    >
                      <div class="text-block-2">Article</div>
                    </a>
                    <div class="icon-5 w-icon-dropdown-toggle"></div>
                  </div>
                  <nav
                    class="nav-dropdown-list shadow-three mobile-shadow-hide w-dropdown-list"
                  >
                    <div class="div-block-2">
                      <div
                        data-hover="true"
                        data-delay="0"
                        class="nav-dropdown-2 w-dropdown"
                      >
                        <div
                          class="nav-dropdown-toggle-2 w-dropdown-toggle"
                          @click="move()"
                        >
                          <div class="droptext2">Gordun Duff</div>
                          <!-- <div class="icon-5 w-icon-dropdown-toggle"></div> -->
                        </div>

                        <nav
                          class="nav-dropdown-list-2 shadow-three mobile-shadow-hide w-dropdown-list"
                        >
                          <div class="drop2div">
                            <a
                              href="/single-article?id=1"
                              class="nav-dropdown-link-2 w-dropdown-link"
                              >The Starnge Case</a
                            >
                            <a
                              href="/single-article?id=2"
                              class="nav-dropdown-link-2 w-dropdown-link"
                              >Freeing Dr.Aafia</a
                            >
                          </div>
                        </nav>
                      </div>
                      <a
                        href="/single-article?id=3"
                        class="nav-dropdown-link w-dropdown-link"
                        >Yvonne Ridley</a
                      >
                      <a
                        href="/single-article?id=4"
                        class="nav-dropdown-link w-dropdown-link"
                        >Najid Ahmed</a
                      >
                      <a
                        href="/single-article?id=5"
                        class="nav-dropdown-link w-dropdown-link"
                        >Clive Smith</a
                      >
                    </div>
                  </nav>
                </div>
              </li>
              <li>
                <a
                  href="/documents"
                  class="nav-link"
                  :class="{ 'w--current': this.$route.name == 'documents' }"
                  >Documents</a
                >
              </li>
              <li>
                <a
                  href="/news"
                  class="nav-link"
                  :class="{
                    'w--current':
                      this.$route.name == 'news' ||
                      this.$route.name == 'single-news' ||
                      newsVisible,
                  }"
                  >News</a
                >
              </li>
              <li>
                <a
                  href="/videos"
                  class="nav-link"
                  :class="{ 'w--current': this.$route.name == 'videos' }"
                  >Videos</a
                >
              </li>
              <!-- <li>
                <a
                  href="/events"
                  class="nav-link"
                  :class="{ 'w--current': this.$route.name == 'events' }"
                  >Events</a
                >
              </li> -->
              <li>
                <a
                  href="/pledge"
                  class="nav-link"
                  :class="{ 'w--current': this.$route.name == 'pledge' }"
                  >Pledge</a
                >
              </li>
              <li>
                <a
                  href="/contact"
                  class="nav-link"
                  :class="{ 'w--current': this.$route.name == 'contact' }"
                  >Contact Us</a
                >
              </li>
              <li>
                <a href="http://archive.aafiamovement.com/" class="nav-link"
                  >Urdu</a
                >
              </li>
              <li class="mobile-margin-top-10">
                <div class="nav-button-wrapper">
                  <a
                    @click="toggleDonate('open')"
                    data-w-id="be5c5993-4f79-d253-2d3c-1a189defd3f2"
                    href="#"
                    class="button-primary w-button"
                    >Donate Now</a
                  >
                  <!-- @click="setButton" -->
                  <a
                    href="https://linktr.ee/aafiamovement"
                    target="_blank"
                    class="button-primary w-button mobile-view-button"
                    >CALL TO ACTION</a
                  >
                  <a
                    href="http://bit.ly/AMACTIVISTS"
                    target="_blank"
                    class="button-primary-white w-button mobile-view-button"
                    >Volunteer Now</a
                  >
                </div>
              </li>
            </ul>
          </nav>
          <div class="menu-button w-nav-button" @click="setButton">
            <div
              class="icon w-icon-nav-menu"
              :class="{ 'my-content set-fontsize': isOpen }"
            ></div>
            <!--  -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="donatepopup wf-section" @click="toggleDonate('close')">
    <h1 style="color: white; font-size: 30px" @click.stop>Account Details</h1>
    <div class="donationdiv" @click.stop>
      <h3 class="donationheading">Bank Name</h3>
      <h4 class="donationdetail">{{ bankObj.name }}</h4>
      <h3 class="donationheading">Title of Account</h3>
      <h4 class="donationdetail">{{ bankObj.title }}</h4>
      <h3 class="donationheading">Account Number</h3>
      <div style="display: flex; justify-content: space-between">
        <h4 class="donationdetail" id="account">{{ bankObj.account }}</h4>
        <img
          @click="copy('account')"
          width="20"
          height="20"
          src="https://img.icons8.com/pastel-glyph/64/copy--v1.png"
          alt="copy--v1"
          style="cursor: pointer"
        />
      </div>
      <h3 class="donationheading">Swift Code</h3>
      <div style="display: flex; justify-content: space-between">
        <h4 class="donationdetail" id="swift">{{ bankObj.swift }}</h4>
        <img
          @click="copy('swift')"
          width="20"
          height="20"
          src="https://img.icons8.com/pastel-glyph/64/copy--v1.png"
          alt="copy--v1"
          style="cursor: pointer"
        />
      </div>
      <h3 class="donationheading">Branch Code</h3>
      <div style="display: flex; justify-content: space-between">
        <h4 class="donationdetail" id="branch">{{ bankObj.branchCode }}</h4>
        <img
          @click="copy('branch')"
          width="20"
          height="20"
          src="https://img.icons8.com/pastel-glyph/64/copy--v1.png"
          alt="copy--v1"
          style="cursor: pointer"
        />
      </div>
    </div>
    <!-- href="https://muslimgiving.org/freeaafia" -->
    <a
      v-if="showOnline"
      href="https://www.muslimgiving.org/aafia"
      target="_blank"
      class="button-primary w-button online-donate"
      >Donate Online</a
    >
    <div data-w-id="44567913-62ce-5393-d531-c4ee0782d899" class="closeicon">
      X
    </div>
  </section>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      scrollPosition: null,
      isOpen: false,
      newsVisible: false,
      articleVisible: false,
      bankObj: {
        name: "United Bank Limited",
        title: "AAFIYAT WELFARE TRUST",
        account: "PK31UNIL 0109000200892566",
        swift: "unilpkka",
        branchCode: "1285",
      },
      showOnline: true,
      expireDate: "2023-08-01",
    };
  },
  computed: {
    screenSize() {
      return window.innerWidth > 991;
    },
    screenHeight() {
      return window.innerHeight;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    // console.log(this.$route, this.$route.name);

    // online donation link visibility
    // let date = new Date();
    // if (date >= new Date(this.expireDate)) this.showOnline = false;
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
      // console.log(this.scrollPosition);

      const element = document.getElementById("news");
      if (element) {
        const rect = element.getBoundingClientRect();

        this.newsVisible =
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth);
      }

      const el = document.getElementById("articles");
      if (el) {
        const rect2 = el.getBoundingClientRect();

        this.articleVisible =
          rect2.top >= 0 &&
          rect2.left >= 0 &&
          rect2.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect2.right <=
            (window.innerWidth || document.documentElement.clientWidth);
      }
    },

    copy(type) {
      let num = document.getElementById(type).innerText;
      navigator.clipboard.writeText(num);
    },
    setButton() {
      this.isOpen = !this.isOpen;
      document.body.style.overflow = this.isOpen ? "hidden" : "auto";
    },

    toggleDonate(text) {
      const sec = document.getElementsByClassName("donatepopup");
      sec[0].style.display = text == "open" ? "flex" : "none";

      // to adjust nav-menu
      this.isOpen = false;
      document.body.style.overflow = "auto";
    },

    move() {
      // console.log(location);
      location.assign(`${location.origin}/single-article?id=1`);
    },
  },
};
</script>
