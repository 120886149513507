import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import News from "../views/News.vue";
import SingleNews from "../views/singleNews.vue";
import Articles from "../views/Articles.vue";
import SingleArticle from "../views/singleArticle.vue";
import Documents from "../views/Documents.vue";
import Contact from "../views/Contact.vue";
import Videos from "../views/Videos.vue";
import Events from "../views/Events.vue";
import NotFound from "../views/NotFound.vue";
import Pledge from "../views/Pledge.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/news",
    name: "news",
    component: News,
  },
  {
    path: "/single-news",
    name: "single-news",
    component: SingleNews,
  },
  {
    path: "/articles",
    name: "articles",
    component: Articles,
  },
  {
    path: "/single-article",
    name: "single-article",
    component: SingleArticle,
  },
  // {
  //   path: "/single-articles",
  //   name: "single-article",
  //   component: function () {
  //     return import("../views/articles/gordun.vue");
  //   },
  // },
  {
    path: "/documents",
    name: "documents",
    component: Documents,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/videos",
    name: "videos",
    component: Videos,
  },
  {
    path: "/events",
    name: "events",
    component: Events,
  },
  {
    path: "/votes",
    name: "votes",
    component: () => import("../views/Votes.vue"),
  },
  {
    path: "/pledge",
    name: "pledge",
    component: Pledge,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: NotFound,
  },

  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "about" */ "../views/AboutView.vue");
  //   },
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
