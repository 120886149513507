<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <Header />
  <router-view />
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  mounted() {
    this.$store.commit(
      "setToken",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhZG1pbkBhYWZpYS5jb20iLCJqdGkiOiI5ZjhjODA5My04Mjk3LTQ3Y2QtYTlkZi03MWVjZjRmMDIwMzMiLCJleHAiOjE3NjQ4MzM5MjMsImlzcyI6Im15c2tvb2wuYXBwIiwiYXVkIjoibXlza29vbC5hcHAifQ.m-tlyltDmkmQx85BaOFfomNDX3aL6BxlUqJFI9x9UTY"
    );
  },
};
</script>
<style></style>
