import { createStore } from "vuex";

import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default createStore({
  plugins: [vuexLocal.plugin],
  state: {
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhZG1pbkBhYWZpYS5jb20iLCJqdGkiOiI5ZjhjODA5My04Mjk3LTQ3Y2QtYTlkZi03MWVjZjRmMDIwMzMiLCJleHAiOjE3NjQ4MzM5MjMsImlzcyI6Im15c2tvb2wuYXBwIiwiYXVkIjoibXlza29vbC5hcHAifQ.m-tlyltDmkmQx85BaOFfomNDX3aL6BxlUqJFI9x9UTY",
    articles: [],
    news: [],
    slides: [],
  },
  getters: {},
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    setArticles(state, payload) {
      state.articles = payload;
    },
    setNews(state, payload) {
      state.news = payload;
    },
    setSlides(state, payload) {
      state.slides = payload;
    },
  },
  actions: {},
  modules: {},
});
